import React, { Component, useState, useRef, useEffect } from "react";
import "./QRCodeGen.scss";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import momentjs from "moment-timezone";
import moment from "moment";
import {
  Layout,
  Popconfirm,
  Menu,
  Breadcrumb,
  // Icon,
  PageHeader,
} from "antd";
import QRCodeStyling from "qr-code-styling";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Select, MenuItem, InputLabel } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import Autocomplete from "@mui/material/Autocomplete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import logo from "../../images/new-logo.png";
import { logout } from "../../actions/loginActions";
import { countryCode, country_code_with_ISO, sgList } from "../../constants";
import countryCodes from "../../countryCodes";
const { SubMenu } = Menu;
import {
  LogoutOutlined,
  UserOutlined,
  SolutionOutlined,
  NotificationOutlined,
  AccountBookOutlined,
} from "@ant-design/icons";
import { fetchUserbyMobile } from "../../actions/general";

const cookies = new Cookies();

let QRCodeGenerate = (props) => {
  console.log({ props });
  const navigate = useNavigate();
  const [warningText, setWarningText] = useState("");
  const [mobile, setMobile] = useState("");
  const [loading, setLoading] = useState(false);
  const [userFound, setUserFound] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [campaignId, setCampaignId] = useState("");
  const [leadOwner, setLeadOwner] = useState("");
  const [collegeName, setCollegeName] = useState("");
  const [location, setLocation] = useState("");
  const [generatedUrl, setGeneratedUrl] = useState("");
  const [copyMessage, setCopyMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorLeadMessage, setErrorLeadMessage] = useState("");
  const [errorCollegeMessage, setErrorCollegeMessage] = useState("");

  const qrCodeRef = useRef(null);
  // const qrCode = useRef(
  //     new QRCodeStyling({
  //         width: 300,
  //         height: 300,
  //         image:
  //             "https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg",
  //         dotsOptions: {
  //             color: "#4267b2",
  //             type: "rounded",
  //         },
  //         imageOptions: {
  //             crossOrigin: "anonymous",
  //             margin: 20,
  //         },
  //     })
  // );

  const qrCode = useRef(
    new QRCodeStyling({
      type: "canvas",
      image: "https://cdn.pegasus.imarticus.org/images/imarticus-icon.svg",
      width: 400,
      height: 400,
      margin: 24,
      qrOptions: {
        typeNumber: "0",
        mode: "Byte",
        errorCorrectionLevel: "Q",
      },
      imageOptions: {
        saveAsBlob: true,
        hideBackgroundDots: true,
        imageSize: 0.5,
        margin: 10,
      },
      dotsOptions: {
        type: "rounded",
        color: "#6a1a4c",
        roundSize: true,
        gradient: {
          type: "radial",
          rotation: 0,
          colorStops: [
            { offset: 0, color: "#14c89b" },
            { offset: 1, color: "#0e715a" },
          ],
        },
      },
      cornersSquareOptions: {
        type: "",
        color: "#035642",
        gradient: {
          type: "linear",
          rotation: 0.7853981633974483,
          colorStops: [
            { offset: 0, color: "#035642" },
            { offset: 1, color: "#048e6c" },
          ],
        },
      },
      cornersDotOptions: {
        type: "",
        color: "#035642",
        gradient: {
          type: "linear",
          rotation: 0.7853981633974483,
          colorStops: [
            { offset: 0, color: "#035642" },
            { offset: 1, color: "#048e6c" },
          ],
        },
      },
      backgroundOptions: {
        round: 0,
        color: "#ffffff",
      },
    })
  );

  useEffect(() => {
    if (qrCodeRef.current && generatedUrl) {
      qrCode.current.update({
        data: generatedUrl,
      });
      qrCode.current.append(qrCodeRef.current);
    }
  }, [generatedUrl]);

  const handleCampaignIdChange = (event) => {
    setCampaignId(event.target.value);
  };
  const handleLeadOwnerChange = (event) => {
    setLeadOwner(event.target.value);
  };
  const handleCollegeNameChange = (event) => {
    setCollegeName(event.target.value);
  };
  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleGenerateUrl = () => {
    const uuidRegex =
      /^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/;

    const leadOwnerRegex = /^[A-Za-z]+\.[A-Za-z]+\d+$/;
    const collegeNameRegex = /^[A-Za-z0-9]+$/;

    if (!uuidRegex.test(campaignId)) {
      setErrorMessage(
        "Invalid Campaign ID format. Please enter a valid Campaign ID."
      );
      setGeneratedUrl("");
      return;
    }

    setErrorMessage("");
    if (leadOwner.length > 50) {
      setErrorCollegeMessage(
        "College Name cannot exceed 100 characters. Please shorten the name."
      );
      setGeneratedUrl("");
      return;
    }
    if (!leadOwnerRegex.test(leadOwner)) {
      setErrorLeadMessage(
        "Invalid Lead Owner format. Please enter a valid Lead Owner."
      );
      setGeneratedUrl("");
      return;
    }
    setErrorLeadMessage("");
    if (collegeName.length > 100) {
      setErrorCollegeMessage(
        "College Name cannot exceed 100 characters. Please shorten the name."
      );
      setGeneratedUrl("");
      return;
    }
    if (!collegeNameRegex.test(collegeName)) {
      setErrorCollegeMessage(
        "Invalid College Name format. Please enter a valid College Name."
      );
      setGeneratedUrl("");
      return;
    }

    setErrorCollegeMessage("");

    const url = `https://imarticus.org/Imarticus-EmploymentAssessmentTest/?c=${encodeURIComponent(
      campaignId
    )}&o=${encodeURIComponent(leadOwner)}&college=${encodeURIComponent(
      collegeName
    )}&location=${encodeURIComponent(location)}`;
    setGeneratedUrl(url);
    setCopyMessage("");
  };
  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(generatedUrl)
      .then(() => setCopyMessage("URL copied to clipboard!"))
      .catch(() => setCopyMessage("Failed to copy URL."));
  };

  const handleDownloadQRCode = () => {
    qrCode.current.download({ extension: "png" });
  };

  const isButtonDisabled =
    !campaignId || !leadOwner || !collegeName || !location;

  if (loading)
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    );
  return (
    <div className="cashpayment">
      <h3>
        Generate ILNET QR codes by adding Campaign ID,&nbsp;Lead
        Owner,&nbsp;College Name
      </h3>
      <hr style={{ width: "100%", margin: "-1rem 0 2rem" }} />
      <div className="row" style={{ justifyContent: "space-between" }}>
        <h5>Enter the Campaign ID,&nbsp;Lead Owner,&nbsp;College Name</h5>

        <div className="row">
          <div
            className="col-md-8"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="row">
              <div className="col-md-3">
                <TextField
                  id="campaignId"
                  value={campaignId}
                  onChange={handleCampaignIdChange}
                  label="Campaign Id"
                  variant="outlined"
                  error={!!errorMessage}
                  helperText={errorMessage}
                  placeholder="1b6e776f-1286-4925-b1dd-e409bc2f8864"
                />
                <p>
                  **Enter in this format -(e.g:
                  1b6e776f-1286-4925-b1dd-e409bc2f8864)
                </p>
              </div>
              <div className="col-md-3">
                <TextField
                  id="leadOwner"
                  value={leadOwner}
                  onChange={handleLeadOwnerChange}
                  label="Lead Owner"
                  variant="outlined"
                  error={!!errorLeadMessage}
                  helperText={errorLeadMessage}
                  placeholder="ravi.kumar1"
                />
                <p>**Enter in this format -(e.g: ravi.kumar1)</p>
                {/* <Tooltip
                  title=""
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, 10],
                        },
                      },
                    ],
                  }}
                  arrow
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip> */}
              </div>
              <div className="col-md-3">
                <TextField
                  id="collegeName"
                  value={collegeName}
                  onChange={handleCollegeNameChange}
                  label="College Name"
                  variant="outlined"
                  error={!!errorCollegeMessage}
                  helperText={errorCollegeMessage}
                  placeholder="FergussonCollege"
                />
                <p>**Enter in this format -(e.g: FergussonCollege)</p>
              </div>
              <div className="col-md-3">
                {/* <TextField
                  id="collegeName"
                  value={collegeName}
                  onChange={handleCollegeNameChange}
                  label="College Name"
                  variant="outlined"
                  error={!!errorCollegeMessage}
                  helperText={errorCollegeMessage}
                  placeholder="FergussonCollege"
                /> */}
                <InputLabel id="collegeName-label">
                  Select a Location
                </InputLabel>
                <Select
                  labelId="collegeName-label"
                  id="collegeName"
                  value={location}
                  onChange={handleLocationChange}
                  label="College Name"
                  style={{ width: "100%" }}
                >
                  <MenuItem value="" disabled>
                    Select a location
                  </MenuItem>
                  {sgList.map((loc, index) => {
                    return (
                      <MenuItem key={index} value={loc}>
                        {loc}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div className="col-md-3">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleGenerateUrl}
                  disabled={isButtonDisabled} // Disable button if any field is empty
                  style={{ marginTop: "20px" }}
                >
                  Generate URL
                </Button>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            {generatedUrl && (
              <div style={{ marginTop: "20px" }}>
                <strong>Generated URL:</strong>
                <p>
                  <a
                    href={generatedUrl}
                    onClick={(e) => {
                      e.preventDefault();
                      handleCopyToClipboard();
                    }}
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      color: "blue",
                    }}
                  >
                    {generatedUrl}
                  </a>
                </p>
                {copyMessage && <p style={{ color: "green" }}>{copyMessage}</p>}
                <div ref={qrCodeRef} style={{ marginTop: "20px" }}></div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleDownloadQRCode}
                  style={{ marginTop: "20px" }}
                >
                  Download QR Code
                </Button>
                <p style={{ paddingTop: "10px" }}>
                  *** Please test this QR code once before going for the event
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  appreview: state.appreview,
  permissions: state.login.permissions,
  resources: state.login.resources,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeGenerate);

import React, { Component, useState, useEffect } from "react";
import "./ViewPlan.scss";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Cookies from "universal-cookie";
import momentjs from "moment-timezone";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Autocomplete, Chip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import DoneIcon from "@mui/icons-material/Done";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import { Info, Download, Send, ErrorOutline } from "@mui/icons-material";
// import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Paper } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import { cyan, green, lime, red } from "@mui/material/colors";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { teal } from "@mui/material/colors";
import {
  resendUserInvoice,
  toggleInstallmentCollectionModeApi,
  resendTransactionNotification,
  sendeNachPaymentLinkToUser,
  retryPayment,
} from "../../../actions/general";
import {
  fetchCourses,
  fetchFeeStructure,
} from "../../../actions/editFeeAction";
import EntryDelete from "./EntryDelete/EntryDelete";
import PseudoBatchEdit from "./PseudoBatchEdit/PseudoBatchEdit";

const style = {
  position: "absolute",
  top: "7%",
  left: "10%",
  transform: "translate(0%, -8%)",
  width: "80%",
  height: "98%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const label = { inputProps: { "aria-label": "Switch demo" } };
const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: teal[600],
    "&:hover": {
      backgroundColor: alpha(teal[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: teal[600],
  },
}));
const OPS_LEVEL_ONE = 21,
  OPS_LEVEL_TWO = 22,
  OPS_LEVEL_THREE = 23,
  OPS_LEVEL_FOUR = 24;
let ViewPlan = ({ open, user, ...props }) => {
  let paymentModes = [
    "PayU",
    "Net Banking",
    "Cash Payment",
    "Cheque",
    "Trade Advance",
    "UPI",
  ];
  console.log({ transaction: props.transaction, user, props });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [invoiceLoadingArr, setInvoiceLoadingArr] = useState([]);
  const [notificationLoadingArr, setNotificationLoadingArr] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [paymentType, setPaymentType] = useState("");
  const [advisorPermissions, setAdvisorPermissions] = useState(
    props.permissions[OPS_LEVEL_ONE] || []
  );
  const [salesHeadPermissions, setSalesHeadPermissions] = useState(
    props.permissions[OPS_LEVEL_TWO] || []
  );
  const [businessHeadPermissions, setBusinessHeadPermissions] = useState(
    props.permissions[OPS_LEVEL_THREE] || []
  );
  const [financesHeadPermissions, setFinanceHeadPermissions] = useState(
    props.permissions[OPS_LEVEL_FOUR] || []
  );
  const [expectedAmount, setExpectedAmount] = useState(0);
  const [latestPackage, setLatestPackage] = useState(null);
  // const [managerOtpSent, setManagerOtpSent] = useState(false);
  // const [otp, setOtp] = useState("");
  const [branchCollected, setBranchCollected] = useState(true);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState(null);
  const [trxnNumber, setTrxnNumber] = useState("");
  const [managerEmail, setmanagerEmail] = useState(null);
  const [emis, setEmis] = useState([]);
  const [installments, setInstallments] = useState([]);
  const [subventionAmount, setSubventionAmount] = useState(0);
  const [transaction, setTransaction] = useState(props.transaction || null);
  const [singleDelete, setSingleDelete] = useState(true);
  const [showEntryDeleteModal, setShowEntryDeleteModal] = useState(false);
  const [showPseudoBatchModal, setShowPseudoBatchModal] = useState(false);

  useEffect(() => {
    console.log("componentDidMount");
    console.log({ transaction });
    props.fetchCourses();
    let packageFound = transaction?.updated_package;
    // if (transaction?.status != "SUCCESS") {
    let paymentTypesTemp = [...paymentTypes];
    if (
      transaction.status != "SUCCESS" &&
      !(
        transaction?.full_trxn_id ||
        transaction?.installmentPlanId ||
        transaction?.emiPlanId
      )
    )
      paymentTypesTemp.push("Balance Fees (Full Payment)");
    if (
      transaction.status != "SUCCESS" &&
      packageFound.reg_price &&
      !transaction?.reg_trxn_id &&
      !transaction?.full_trxn_id
    )
      paymentTypesTemp.push("Registration Fees");
    if (
      transaction.status != "SUCCESS" &&
      packageFound.app_fees &&
      !transaction?.application_trxn_id &&
      !transaction?.full_trxn_id
    )
      paymentTypesTemp.push("Application Fees");
    if (transaction?.installmentPlanId && !transaction?.full_trxn_id) {
      console.log({ transaction });
      console.log({
        "transaction?.updated_package": transaction?.updated_package,
        "transaction?.updated_package.installmentPlans":
          transaction?.updated_package.installmentPlans,
      });
      let index = transaction?.updated_package.installmentPlans?.findIndex(
        (plan) => plan._id == transaction?.installmentPlanId
      );
      var plan;
      console.log({ index });
      if (index != -1)
        plan = transaction?.updated_package.installmentPlans[index];
      console.log("settingINstallments");
      console.log({
        "transaction?.updated_package.installmentPlans[index].installments":
          transaction?.updated_package.installmentPlans[index].installments,
      });
      if (plan._id == transaction?.override?.overrides?.installmentPlan?._id)
        plan = transaction?.override?.overrides?.installmentPlan;
      setInstallments(plan.installments);
      if (
        transaction.status != "SUCCESS" &&
        plan &&
        transaction?.installment_trxn_ids.length < plan.installments.length
      )
        paymentTypesTemp.push("Balance Fees (Installment Payment)");
    }
    if (transaction?.emiPlanId && !transaction?.full_trxn_id) {
      let index = transaction?.updated_package.emiPlans?.findIndex(
        (plan) => plan._id == transaction?.emiPlanId
      );
      var plan;
      if (index != -1) {
        plan = transaction?.updated_package.emiPlans[index];
        setEmis(transaction?.updated_package.emiPlans[index].emis);
      }
      if (
        transaction.status != "SUCCESS" &&
        plan &&
        transaction?.emi_trxn_ids.length < plan.emis.length
      )
        paymentTypesTemp.push("Balance Fees (Emi Payment)");
    }
    console.log({ paymentTypesTemp });
    setPaymentTypes(paymentTypesTemp);
    // }
  }, []);
  useEffect(() => {
    console.log({ props });
    if (props.editfee?.coursePackages) {
      let packageIndex = props.editfee?.coursePackages.findIndex(
        (el) => el._id == transaction?.updated_package._id
      );
      console.log({
        latestPackage: props.editfee?.coursePackages[packageIndex],
      });
      setLatestPackage(props.editfee?.coursePackages[packageIndex]);
    }
  });
  let toggleInstallmentCollectionMode = async () => {
    if (
      !window.confirm(
        `Are you sure you want to toggle the Installment collection mode?`
      )
    )
      return;
    setLoading(true);
    console.log({ "transaction?._id": transaction?._id });
    let { success, message, ...data } =
      await toggleInstallmentCollectionModeApi({
        trxn_id: transaction?._id,
      });
    setLoading(false);
    console.log({ success, message, data });
    if (!success) {
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      return;
    }
    console.log({ "data.transactionFound": data.transactionFound });
    props.refresh();
  };
  let convertPriceToLocalString = function (amount) {
    // console.log({amount})
    return parseFloat(String(amount)).toLocaleString("hi");
  };
  let pluralise = (n) => {
    return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
  };
  let getDate = (date) => {
    return momentjs(date).tz(momentjs.tz.guess()).format("lll");
  };
  let downloadInvoice = (invoice_url, err) => {
    console.log({ invoice_url, "typeof invoice_url": typeof invoice_url });
    if (invoice_url && typeof invoice_url == "string")
      window.open(invoice_url, "_blank");
    else window.alert(err);
  };
  let resendInvoice = async (index) => {
    setLoading(true);
    let { success, message, ...data } = await resendUserInvoice({
      trxn_id: transaction._id,
      index,
    });
    setLoading(false);
    console.log({ success, message, data });
    if (!success) {
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      return;
    }
    alert("Invoice is sent to the user");
    props.refresh();
  };
  let resendNotification = async (transactionId) => {
    setLoading(true);
    let { success, message, ...data } = await resendTransactionNotification({
      transactionId,
    });
    setLoading(false);
    console.log({ success, message, data });
    if (!success) {
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      return;
    }
    alert("Notification is sent to the user");
    props.refresh();
  };
  let sendeNachLink = async () => {
    setLoading(true);
    let { success, message, ...data } = await sendeNachPaymentLinkToUser({
      aid: transaction.aid,
      trxn_id: transaction._id,
    });
    setLoading(false);
    console.log({ success, message, data });
    if (!success) {
      if (message) {
        window.alert(message);
      } else {
        window.alert("Something went wrong. Please try again.");
      }
      return;
    }
    alert("eNach link is sent to the user");
  };

  const handleRetryPayment = async ({
    payment_type,
    installment_index,
    installment_plan_id,
    emi_index,
    emi_plan_id,
  }) => {
    setLoading(true);
    const { success, message, ...data } = await retryPayment({
      aid: user._id,
      crs_pg_id: transaction.crs_pg_id,
      trxn_id: transaction._id,
      package_id: transaction.package_id,
      payment_type,
      installment_index,
      installment_plan_id,
      emi_index,
      emi_plan_id,
    });
    setLoading(false);
    console.log({ success, message, data });
    if (!success) {
      if (message) {
        alert(message);
      } else {
        alert("Something went wrong. Please try again.");
      }
      return;
    }
    if (message) {
      alert(message);
    } else {
      alert("Payment has been successfully captured.");
    }
    props.refresh();
  };

  const isRetryAvailable = (type, index) => {
    const isRegistrationPaid = isRegFeesPaid();
    const isApplicationPaid = isAppFeesPaid();

    if (type == 1) {
      if (isRegistrationPaid && isApplicationPaid) {
        return true;
      }
    } else if (type == 2) {
      if (index == 0) {
        if (isRegistrationPaid && isApplicationPaid) {
          return true;
        }
      } else {
        if (transaction.installment_trxn_ids[index - 1]) {
          return true;
        }
      }
    } else {
      if (index == 0) {
        if (isRegistrationPaid && isApplicationPaid) {
          return true;
        }
      } else {
        if (transaction.emi_trxn_ids[index - 1]) {
          return true;
        }
      }
    }

    return false;
  };

  const isRegFeesPaid = () => {
    if (transaction.updated_package.reg_price) {
      if (transaction.reg_trxn_id) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const isAppFeesPaid = () => {
    if (transaction.updated_package.app_fees) {
      if (transaction.application_trxn_id) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <Modal
      open={open}
      onClose={props.closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        className="ViewPlan"
        style={{ justifyContent: "flex-start" }}
      >
        {loading ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <div className="row" style={{ flexDirection: "column" }}>
              {showEntryDeleteModal && (
                <EntryDelete
                  open={showEntryDeleteModal}
                  closeModal={() => {
                    setShowEntryDeleteModal(false);
                  }}
                  singleDelete={singleDelete}
                  transaction={transaction}
                  refresh={props.refresh}
                  configs={props.configs}
                  user={user}
                />
              )}
              {showPseudoBatchModal && (
                <PseudoBatchEdit
                  open={showPseudoBatchModal}
                  closeModal={() => {
                    setShowPseudoBatchModal(false);
                  }}
                  transaction={transaction}
                  refresh={props.refresh}
                />
              )}
              <Typography id="modal-modal-title" variant="h4">
                View payment Details
              </Typography>
              <Typography id="modal-modal-title2" variant="h5">
                Invoice status
              </Typography>
              <TableContainer className="mainTable" component={Paper}>
                <Table aria-label="custom pagination table">
                  <TableHead>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Number
                      </TableCell>
                      <TableCell component="th" scope="row">
                        Invoice Number
                      </TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell style={{ color: lime[900] }}>
                        Download Invoice
                      </TableCell>
                      <TableCell style={{ color: lime[900] }}>
                        Resend Invoices
                      </TableCell>
                      <TableCell style={{ color: lime[900] }}>
                        Invoices send retries
                      </TableCell>
                      {/* <TableCell>
                        <span style={{ color: green[700] }}>
                          Total Approved
                        </span>
                      </TableCell>
                      <TableCell>
                        <span style={{ color: red[700] }}>Total Rejected</span>
                      </TableCell>
                      <TableCell>
                        <span style={{ color: cyan[700] }}>Total Applied</span>
                      </TableCell> */}
                    </TableRow>
                  </TableHead>
                  {transaction?.zoho_invoice_responses?.length > 0 &&
                  transaction?.zoho_invoice_responses.some(
                    (dt, idx) => transaction.invoiceUrls[idx]
                  ) ? (
                    <TableBody>
                      {transaction?.zoho_invoice_responses?.map(
                        (
                          row,
                          index //name, location, l_date
                        ) => {
                          if (transaction.invoiceUrls[index]) {
                            return (
                              <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                  {`${index + 1}.`}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {`${row.invoice.invoice_number}`}
                                </TableCell>
                                <TableCell>{`${
                                  transaction?.curr
                                } ${convertPriceToLocalString(
                                  row.invoice.bcy_total || 0
                                )}  ${
                                  row.invoice.credits_applied
                                    ? "- " +
                                      transaction?.curr +
                                      " " +
                                      convertPriceToLocalString(
                                        row.invoice.credits_applied
                                      ) +
                                      " (discount)"
                                    : ""
                                }`}</TableCell>
                                <TableCell>
                                  <Button
                                    variant="contained"
                                    onClick={() =>
                                      downloadInvoice(
                                        transaction.invoiceUrls[index]?.url,
                                        transaction.invoiceUrls[index]?.err
                                      )
                                    }
                                    // style={{ width: "150px" }}
                                    color="success"
                                  >
                                    <Download />
                                    Download Invoice
                                  </Button>
                                </TableCell>
                                <TableCell>
                                  <Button
                                    variant="contained"
                                    onClick={() => resendInvoice(index)}
                                    // style={{ width: "150px" }}
                                    color="info"
                                  >
                                    <Send />
                                    Resend Invoice
                                  </Button>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {`${row.invoiceRetries || 0}`}
                                </TableCell>
                              </TableRow>
                            );
                          } else {
                            return null;
                          }
                        }
                      )}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={5}
                          align="center"
                        >
                          <span
                            style={{ textAlign: "center", fontSize: "18spanx" }}
                          >
                            No invoices Generated.
                          </span>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              <br />
              <br />
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell variant="head">User</TableCell>
                      <TableCell>{user?.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Course</TableCell>
                      <TableCell>{transaction?.courseName}</TableCell>
                    </TableRow>
                    <TableRow>
                      {/* <TableCell variant="head">Payment Type</TableCell> */}
                      {/* <TableCell>
                        {transaction?.installment_ids.length > 0
                          ? "Installments"
                          : "Full Payment"}
                      </TableCell> */}
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Payment Mode</TableCell>
                      <TableCell>
                        <b>
                          {transaction?.installmentPlanId
                            ? `Pay Via Installments`
                            : transaction?.emiPlanId
                            ? `Pay Via EMIs`
                            : "Full Payment"}
                        </b>
                      </TableCell>
                    </TableRow>
                    {transaction?.installmentPlanId && (
                      <TableRow>
                        <TableCell variant="head">
                          Installment Collection Mode
                        </TableCell>
                        <TableCell>
                          <b>
                            {transaction?.installmentCollectionType == 2
                              ? `Collection Via eNach Mandate`
                              : `Pay Via Imarticus Website`}
                          </b>
                          <div class="row" style={{}}>
                            <Chip
                              label={
                                transaction?.installmentCollectionType == 2
                                  ? `Convert to Pay Via Website`
                                  : `Convert to Collection Via eNach Mandate`
                              }
                              color="info"
                              onClick={toggleInstallmentCollectionMode}
                              avatar={
                                <PublishedWithChangesIcon
                                  style={{ color: "#000" }}
                                />
                              }
                              style={{ cursor: "pointer" }}
                            />
                            {transaction?.installmentCollectionType == 2 &&
                            !transaction.enach?.mandateCreationDate ? (
                              <>
                                <button
                                  className="ar-vw-app"
                                  style={{
                                    width: "fit-content",
                                    float: "right",
                                    padding: "8px 16px",
                                  }}
                                  onClick={sendeNachLink}
                                >
                                  Resend eNach Registration Link to the Student
                                </button>
                                <div className="tooltip_link">
                                  <CopyToClipboard
                                    text={transaction.eNachRedirectUrl}
                                    // onCopy={() =>
                                    //   this.setState({
                                    //     link_copy_text: "Copied!",
                                    //   })
                                    // }
                                  >
                                    <Tooltip
                                      title={transaction.eNachRedirectUrl}
                                    >
                                      <ContentCopyIcon />
                                    </Tooltip>
                                  </CopyToClipboard>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell variant="head">Pseudo Batch Name</TableCell>
                      <TableCell>
                        <div style={{ display: "flex" }}>
                          {transaction?.pseudoBatchName
                            ? transaction.pseudoBatchName
                            : "-"}
                          <EditIcon
                            className="delete-btn"
                            onClick={() => {
                              setShowPseudoBatchModal(true);
                            }}
                            style={{ marginLeft: "12px" }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Real Batch Name</TableCell>
                      <TableCell>
                        {transaction?.realBatchName
                          ? transaction.realBatchName
                          : "-"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Total Payable Amount</TableCell>
                      <TableCell>
                        {transaction?.updated_package.curr}{" "}
                        {transaction?.fullPayableAmount}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Total Amount Paid</TableCell>
                      <TableCell>
                        {transaction?.updated_package.curr}{" "}
                        {transaction?.fullPaidAmount}
                      </TableCell>
                    </TableRow>
                    {expectedAmount ? (
                      <TableRow>
                        <TableCell variant="head">
                          Next Expected Amount
                        </TableCell>
                        <TableCell>
                          {transaction?.updated_package.curr} {expectedAmount}
                        </TableCell>
                      </TableRow>
                    ) : null}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "200%",
                        fontSize: "22px",
                        marginTop: "28px",
                        marginBottom: "12px",
                      }}
                    >
                      <div>Delete Latest Payment</div>
                      <GreenSwitch
                        {...label}
                        checked={!singleDelete}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setSingleDelete(false);
                          } else {
                            setSingleDelete(true);
                          }
                        }}
                      />
                      <div>Delete All Data</div>
                      <h4 className="tooltipl">
                        <img
                          src="https://webcdn.imarticus.org/demo/ic_baseline-info.svg"
                          height={20}
                          width={20}
                        />
                        <span class="tooltiptextl2">
                          By Selecting this option, All the application details,
                          pre-assessment data, payments, invoices and kaching
                          will be permanently deleted.
                        </span>
                      </h4>
                      <img
                        className="delete-btn"
                        src="https://webcdn.imarticus.org/dsgdj/material-symbols_delete-outline.svg"
                        style={{
                          height: "32px",
                          width: "32px",
                          marginLeft: "24px",
                          color: "red",
                          backgroundColor: "red",
                          borderRadius: "4px",
                        }}
                        onClick={() => {
                          if (transaction.courseCategoryData) {
                            if (transaction.courseCategoryData.admin_email) {
                              if (singleDelete) {
                                if (
                                  transaction.reg_trxn_id ||
                                  transaction.application_trxn_id ||
                                  transaction.full_trxn_id ||
                                  (transaction.installment_trxn_ids &&
                                    transaction.installment_trxn_ids.length) ||
                                  (transaction.emi_trxn_ids &&
                                    transaction.emi_trxn_ids.length)
                                ) {
                                  setShowEntryDeleteModal(true);
                                } else {
                                  alert("No Payment found to delete");
                                }
                              } else {
                                setShowEntryDeleteModal(true);
                              }
                            } else {
                              alert(
                                "Cannot delete the entry since no admin is set for this course category"
                              );
                            }
                          } else {
                            alert(
                              "Cannot delete the entry since no category is set for this course"
                            );
                          }
                        }}
                        alt="delete-btn"
                      />
                    </div>

                    <div
                      style={{
                        fontSize: "22px",
                        marginTop: "28px",
                        marginBottom: "12px",
                        fontWeight: "600",
                      }}
                    >
                      Payments Status
                    </div>
                    {transaction?.updated_package.reg_price ? (
                      <TableRow>
                        <TableCell variant="head">
                          Registration Amount
                        </TableCell>
                        <TableCell>
                          <div
                            className="row"
                            style={{ gap: "1rem", flexDirection: "column" }}
                          >
                            <div className="row">
                              {transaction?.updated_package.curr}{" "}
                              {convertPriceToLocalString(
                                transaction?.override?.overrides?.reg_price ||
                                  transaction?.updated_package.reg_price
                              )}
                              {(transaction?.installment_trxn_ids?.length ||
                                transaction?.emi_trxn_ids?.length ||
                                transaction?.reg_trxn_id) && (
                                <>
                                  <Chip
                                    label="Paid"
                                    color="success"
                                    avatar={
                                      <DoneIcon style={{ color: "#000" }} />
                                    }
                                  />
                                  {transaction?.reg_trxn_id &&
                                    transaction?.datesMap?.[
                                      transaction?.reg_trxn_id
                                    ] && (
                                      <Chip
                                        variant="outlined"
                                        label={getDate(
                                          transaction?.datesMap?.[
                                            transaction?.reg_trxn_id
                                          ]
                                        )}
                                        color="info"
                                      />
                                    )}
                                </>
                              )}
                              {/* </div> */}
                              {/* {transaction.transactions[
                                transaction?.reg_trxn_id
                              ]?.status == "SUCCESS" && (
                                <>
                                  {transaction?.reg_trxn_id && (
                                    <>
                                      <Tooltip
                                        title={`Retries: ${
                                          transaction.transactions[
                                            transaction?.reg_trxn_id
                                          ]?.notificationRetries || 0
                                        }`}
                                      >
                                        {transaction.transactions[
                                          transaction?.reg_trxn_id
                                        ]?.notificationSent ? (
                                          <Chip
                                            label="Notification Sent"
                                            color="success"
                                            avatar={
                                              <DoneIcon
                                                style={{ color: "#000" }}
                                              />
                                            }
                                          />
                                        ) : (
                                          <Chip
                                            label="Notification Not Sent"
                                            color="warning"
                                            avatar={
                                              <ErrorOutline
                                              // style={{ color: "#000" }}
                                              />
                                            }
                                          />
                                        )}
                                      </Tooltip>
                                      <Button
                                        variant="contained"
                                        onClick={() =>
                                          resendNotification(
                                            transaction?.reg_trxn_id
                                          )
                                        }
                                        // style={{ width: "300px" }}
                                        color="info"
                                      >
                                        <Send />
                                        Resend Notification
                                      </Button>
                                    </>
                                  )}
                                </>
                              )} */}
                              <Button
                                variant="contained"
                                onClick={() =>
                                  handleRetryPayment({ payment_type: 4 })
                                }
                                color="info"
                              >
                                <Send />
                                Retry Payment
                              </Button>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {transaction?.updated_package.app_fees ? (
                      <TableRow>
                        <TableCell variant="head">Application Amount</TableCell>
                        <TableCell>
                          <div
                            className="row"
                            style={{ gap: "1rem", flexDirection: "column" }}
                          >
                            <div className="row">
                              {transaction?.updated_package.curr}{" "}
                              {convertPriceToLocalString(
                                transaction?.override?.overrides?.app_fees ||
                                  transaction?.updated_package.app_fees
                              )}
                              {(transaction?.installment_trxn_ids?.length ||
                                transaction?.emi_trxn_ids?.length ||
                                transaction?.application_trxn_id) && (
                                <>
                                  <Chip
                                    label="Paid"
                                    color="success"
                                    avatar={
                                      <DoneIcon style={{ color: "#000" }} />
                                    }
                                  />
                                  {transaction?.application_trxn_id &&
                                    transaction?.datesMap?.[
                                      transaction?.application_trxn_id
                                    ] && (
                                      <Chip
                                        variant="outlined"
                                        label={getDate(
                                          transaction?.datesMap?.[
                                            transaction?.application_trxn_id
                                          ]
                                        )}
                                        color="info"
                                      />
                                    )}
                                </>
                              )}
                              {/* {transaction.transactions[
                                transaction?.application_trxn_id
                              ]?.status == "SUCCESS" && (
                                <>
                                  <Tooltip
                                    title={`Retries: ${
                                      transaction.transactions[
                                        transaction?.application_trxn_id
                                      ]?.notificationRetries || 0
                                    }`}
                                  >
                                    {transaction?.application_trxn_id &&
                                    transaction.transactions[
                                      transaction?.application_trxn_id
                                    ]?.notificationSent ? (
                                      <Chip
                                        label="Notification Sent"
                                        color="success"
                                        avatar={
                                          <DoneIcon style={{ color: "#000" }} />
                                        }
                                      />
                                    ) : (
                                      <Chip
                                        label="Notification Not Sent"
                                        color="warning"
                                        avatar={
                                          <ErrorOutline
                                          // style={{ color: "#000" }}
                                          />
                                        }
                                      />
                                    )}
                                  </Tooltip>
                                  <Button
                                    variant="contained"
                                    onClick={() =>
                                      resendNotification(
                                        transaction?.application_trxn_id
                                      )
                                    }
                                    // style={{ width: "300px" }}
                                    color="info"
                                  >
                                    <Send />
                                    Resend Notification
                                  </Button>
                                </>
                              )} */}
                              <Button
                                variant="contained"
                                onClick={() =>
                                  handleRetryPayment({ payment_type: 5 })
                                }
                                color="info"
                              >
                                <Send />
                                Retry Payment
                              </Button>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {transaction?.updated_package.price &&
                      !(
                        transaction?.installmentPlanId || transaction?.emiPlanId
                      ) && (
                        <TableRow>
                          <TableCell variant="head">
                            Program fee Amount
                          </TableCell>
                          <TableCell>
                            <div
                              className="row"
                              style={{ gap: "1rem", flexDirection: "column" }}
                            >
                              <div className="row">
                                {transaction?.updated_package.curr}{" "}
                                {convertPriceToLocalString(
                                  transaction?.updated_package.price
                                )}
                                {transaction?.full_trxn_id && (
                                  <>
                                    <Chip
                                      label="Paid"
                                      color="success"
                                      avatar={
                                        <DoneIcon style={{ color: "#000" }} />
                                      }
                                    />
                                    {transaction?.full_trxn_id &&
                                      transaction?.datesMap?.[
                                        transaction?.full_trxn_id
                                      ] && (
                                        <Chip
                                          variant="outlined"
                                          label={getDate(
                                            transaction?.datesMap?.[
                                              transaction?.full_trxn_id
                                            ]
                                          )}
                                          color="info"
                                        />
                                      )}
                                  </>
                                )}
                                {/* {transaction.transactions[
                                  transaction?.full_trxn_id
                                ]?.status == "SUCCESS" && (
                                  <>
                                    <Tooltip
                                      title={`Retries: ${
                                        transaction.transactions[
                                          transaction?.full_trxn_id
                                        ]?.notificationRetries || 0
                                      }`}
                                    >
                                      {transaction?.full_trxn_id &&
                                      transaction.transactions[
                                        transaction?.full_trxn_id
                                      ]?.notificationSent ? (
                                        <Chip
                                          label="Notification Sent"
                                          color="success"
                                          avatar={
                                            <DoneIcon
                                              style={{ color: "#000" }}
                                            />
                                          }
                                        />
                                      ) : (
                                        <Chip
                                          label="Notification Not Sent"
                                          color="warning"
                                          avatar={
                                            <ErrorOutline
                                            // style={{ color: "#000" }}
                                            />
                                          }
                                        />
                                      )}
                                    </Tooltip>
                                    <Button
                                      variant="contained"
                                      onClick={() =>
                                        resendNotification(
                                          transaction?.full_trxn_id
                                        )
                                      }
                                      // style={{ width: "300px" }}
                                      color="info"
                                    >
                                      <Send />
                                      Resend Notification
                                    </Button>
                                  </>
                                )} */}
                                {isRetryAvailable(1) && (
                                  <Button
                                    variant="contained"
                                    onClick={() =>
                                      handleRetryPayment({ payment_type: 1 })
                                    }
                                    color="info"
                                  >
                                    <Send />
                                    Retry Payment
                                  </Button>
                                )}
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    {installments && installments.length
                      ? installments.map((installment, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell variant="head">
                                {installment.alias || (
                                  <>
                                    {`${index + 1}${pluralise(index + 1)} 
                                    installment`}
                                  </>
                                )}
                              </TableCell>
                              <TableCell>
                                <div
                                  className="row"
                                  style={{
                                    gap: "1rem",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="row">
                                    {transaction?.updated_package.curr}{" "}
                                    {convertPriceToLocalString(
                                      installment?.amount
                                    )}
                                    {transaction?.installment_trxn_ids[
                                      index
                                    ] && (
                                      <>
                                        <Chip
                                          label="Paid"
                                          color="success"
                                          avatar={
                                            <DoneIcon
                                              style={{ color: "#000" }}
                                            />
                                          }
                                        />
                                        {transaction?.datesMap?.[
                                          transaction?.installment_trxn_ids[
                                            index
                                          ]
                                        ] && (
                                          <Chip
                                            variant="outlined"
                                            label={getDate(
                                              transaction?.datesMap?.[
                                                transaction
                                                  ?.installment_trxn_ids[index]
                                              ]
                                            )}
                                            color="info"
                                          />
                                        )}
                                      </>
                                    )}
                                    {/* {transaction.transactions[
                                      transaction?.installment_trxn_ids[index]
                                    ]?.status == "SUCCESS" && (
                                      <>
                                        <Tooltip
                                          title={`Retries: ${
                                            transaction.transactions[
                                              transaction?.installment_trxn_ids[
                                                index
                                              ]
                                            ]?.notificationRetries || 0
                                          }`}
                                        >
                                          {transaction?.installment_trxn_ids[
                                            index
                                          ] &&
                                          transaction.transactions[
                                            transaction?.installment_trxn_ids[
                                              index
                                            ]
                                          ]?.notificationSent ? (
                                            <Chip
                                              label="Notification Sent"
                                              color="success"
                                              avatar={
                                                <DoneIcon
                                                  style={{ color: "#000" }}
                                                />
                                              }
                                            />
                                          ) : (
                                            <Chip
                                              label="Notification Not Sent"
                                              color="warning"
                                              avatar={
                                                <ErrorOutline
                                                // style={{ color: "#000" }}
                                                />
                                              }
                                            />
                                          )}
                                        </Tooltip>
                                        <Button
                                          variant="contained"
                                          onClick={() =>
                                            resendNotification(
                                              transaction?.installment_trxn_ids[
                                                index
                                              ]
                                            )
                                          }
                                          // style={{ width: "300px" }}
                                          color="info"
                                        >
                                          <Send />
                                          Resend Notification
                                        </Button>
                                      </>
                                    )} */}
                                    {isRetryAvailable(2, index) && (
                                      <Button
                                        variant="contained"
                                        onClick={() =>
                                          handleRetryPayment({
                                            payment_type: 2,
                                            installment_index: index,
                                            installment_plan_id:
                                              transaction?.installmentPlanId,
                                          })
                                        }
                                        color="info"
                                      >
                                        <Send />
                                        Retry Payment
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      : null}
                    {emis && emis.length
                      ? emis.map((emi, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell variant="head">{`${
                                index + 1
                              }${pluralise(index + 1)} emi`}</TableCell>
                              <TableCell>
                                <div
                                  className="row"
                                  style={{
                                    gap: "1rem",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="row">
                                    {transaction?.updated_package.curr}{" "}
                                    {convertPriceToLocalString(emi?.amount)}
                                    {transaction?.emi_trxn_ids[index] && (
                                      <>
                                        <Chip
                                          label="Paid"
                                          color="success"
                                          avatar={
                                            <DoneIcon
                                              style={{ color: "#000" }}
                                            />
                                          }
                                        />
                                        {transaction?.datesMap?.[
                                          transaction?.emi_trxn_ids[index]
                                        ] && (
                                          <Chip
                                            variant="outlined"
                                            label={getDate(
                                              transaction?.datesMap?.[
                                                transaction?.emi_trxn_ids[index]
                                              ]
                                            )}
                                            color="info"
                                          />
                                        )}
                                      </>
                                    )}
                                    {/* {transaction.transactions[
                                      transaction?.emi_trxn_ids[index]
                                    ]?.status == "SUCCESS" && (
                                      <>
                                        <Tooltip
                                          title={`Retries: ${
                                            transaction.transactions[
                                              transaction?.emi_trxn_ids[index]
                                            ]?.notificationRetries || 0
                                          }`}
                                        >
                                          {transaction?.emi_trxn_ids[index] &&
                                          transaction.transactions[
                                            transaction?.emi_trxn_ids[index]
                                          ]?.notificationSent ? (
                                            <Chip
                                              label="Notification Sent"
                                              color="success"
                                              avatar={
                                                <DoneIcon
                                                  style={{ color: "#000" }}
                                                />
                                              }
                                            />
                                          ) : (
                                            <Chip
                                              label="Notification Not Sent"
                                              color="warning"
                                              avatar={
                                                <ErrorOutline
                                                // style={{ color: "#000" }}
                                                />
                                              }
                                            />
                                          )}
                                        </Tooltip>
                                        <Button
                                          variant="contained"
                                          onClick={() =>
                                            resendNotification(
                                              transaction?.emi_trxn_ids[index]
                                            )
                                          }
                                          // style={{ width: "300px" }}
                                          color="info"
                                        >
                                          <Send />
                                          Resend Notification
                                        </Button>
                                      </>
                                    )} */}
                                    {isRetryAvailable(3, index) && (
                                      <Button
                                        variant="contained"
                                        onClick={() =>
                                          handleRetryPayment({
                                            payment_type: 3,
                                            emi_index: index,
                                            emi_plan_id: transaction?.emiPlanId,
                                          })
                                        }
                                        color="info"
                                      >
                                        <Send />
                                        Retry Payment
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            {transaction?.fullPaidAmount == transaction?.fullPayableAmount ? (
              <h4
                style={{
                  margin: "2rem auto auto",
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                Payment is Completed!
              </h4>
            ) : null}
          </>
        )}
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  appreview: state.appreview,
  permissions: state.login.permissions,
  resources: state.login.resources,
  editfee: state.editfee,
});

const mapDispatchToProps = {
  fetchCourses,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPlan);
